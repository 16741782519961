body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
#root {
  width: 100%;
}
* {
  box-sizing: border-box;
}
button {
  outline: none !important;
}
#signup:hover,
#login:hover {
  color: rgb(218 52 85);
}
.css-b62m3t-container {
  width: 100%;
}
#otpContainer::-webkit-scrollbar-track,
#otpHistory ::-webkit-scrollbar {
  width: 15px;
  height: 10px !important;
}

/* Track */
#otpContainer::-webkit-scrollbar-track,
#otpHistory ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
#otpContainer::-webkit-scrollbar-track,
#otpHistory ::-webkit-scrollbar-thumb {
  background: rgb(255, 55, 95);
}

/* Handle on hover */
#otpContainer::-webkit-scrollbar-track,
#otpHistory ::-webkit-scrollbar-thumb:hover {
  background: rgb(194, 7, 44);
}
