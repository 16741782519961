.mytr>td{
    font-size: 1rem;
    text-align: center;
    padding: 0.2rem 0rem !important;
}
@media only screen and (max-width: 600px) {
    .mytr>td{
        font-size: 0.9rem;
    }
}
  